import React, { useEffect , useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import DataTable from "../../component/dataTable/index";
import SettingsIcon from "@material-ui/icons/Settings";
import { PlusCircleFill } from "../../assets/icons/Icons";
import {
  getOperationalAlerts,
  deleteAlert,
  toastTriggered,
} from "../../redux/actions/index";
import DeleteIcon from "@material-ui/icons/Delete";
import toastProperties from "../../assets/data/toastProperty";
import utils from '../../utils/index';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0,
  },
  paper: {
    padding: theme.spacing(0.3, 0),
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxShadow: "none",
    background: "#f4f5f6",
  },
  flexContainer: {
    height: "100%",
    width: "100%",
  },
}));

const Alert = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();

  const OperationalAlerts = useSelector((state) => state.fetchAlerts);
  const { activeRoutes } = useSelector((state) => state.activeDynamicData);
  const selectedRegion = useSelector((state) => state.getBusinessUnitsRegion);
  const [region, setRegion] = useState("");

  const { alerts, loading } = OperationalAlerts;

  useEffect(() => {
    dispatch(getOperationalAlerts());
  }, [dispatch]);

  const trackUserActivities = (item) => {
      let trackingObject = {
        region: item.region,
        actionType: 'Click',
        pageName: "Other Feature",
        reportName: "Alerts",
        reportSection:  'Alert Deleted',
        pageDetails : `${item.alertName}`,
      };
      utils.userTrackingInfo(trackingObject);
  };

  const deleteSelAlert = (dataIndex) => {
    let params = {
      alertID: dataIndex,
    };
    let index = alerts.findIndex((el) => el.alertID === dataIndex);
    dispatch(deleteAlert(params)).then((res) => {
      if (index > -1) {
        trackUserActivities(alerts[index]);
        alerts.splice(index, 1);
        Array.isArray(alerts) &&
        alerts.length > 0 &&
        alerts.forEach((item, index) => {
          item.id = index + 1;
          return alerts;
        });
      };
      toastProperties.success["description"] = res.data.message;
      dispatch(toastTriggered([toastProperties.success]));
    });
  };

  useEffect(() => {
    if (!!selectedRegion && Object.keys(selectedRegion).length > 0) {
      setRegion(selectedRegion.region);
    }
  }, [selectedRegion]);


  useEffect(() => {
    Array.isArray(alerts) &&
      alerts.length > 0 &&
      alerts.forEach((item, index) => {
        item.id = index + 1;
        return alerts;
      });
  }, [alerts]);

  const columns = [
    {
      name: "id",
      label: "S.No.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "region",
      label: "Region",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "alertName",
      label: "Alert Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "alertType",
      label: "Alert Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "category",
      label: "Category",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "subCategory",
      label: "Sub Category",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "createdBy",
      label: "Created By",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "startDate",
      label: "Alert Date",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "startTime",
      label: "Alert Time",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "displayMessage",
      label: "Alert Message",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'showAlert',
      label: "Alert Status",
      options: {
        filter: false,
        sort: false,
      },
    }
  ];

  const title = React.createElement(
    "div",
    { className: "heading" },
    "Operational Alerts"
  );

  const newAlert = React.createElement(
    "div",
    {
      className: "newAlert",
      onClick: () => {
        history.push(activeRoutes.key12);
        utils.userTrackingInfo({
          region : region,
          actionType: 'Click',
          pageName: 'Other Feature',
          reportName: "Alerts",
          pageDetails: `Redirected from Operational Alert to Create Alerts`,
        });
      },
    },
    "Create New Alert"
  );

  const Setting = React.createElement(
    "div",
    {
      className: "alertSetting",
      onClick: () => {
        history.push(activeRoutes.key11);
        utils.userTrackingInfo({
          region : region,
          actionType: 'Click',
          pageName: 'Other Feature',
          reportName: "Alerts",
          pageDetails: `Redirected from Operational Alert to Alert Setting`,
        });
      },
    },
    "Alert Settings"
  );

  const createNewAlert = React.createElement("div", { className: "alertBtn" }, [
    <PlusCircleFill />,
    newAlert,
  ]);

  const alertSetting = React.createElement("div", { className: "alertBtn" }, [
    <SettingsIcon />,
    Setting,
  ]);

  const config = React.createElement("div", { className: "button" }, [
    createNewAlert,
    alertSetting,
  ]);

  const container = React.createElement("div", { className: "titleInfo" }, [
    title,
    config,
  ]);


  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container item xs={12} className={classes.flexContainer}>
          <DataTable
            data={alerts}
            loading={loading}
            title={container}
            columns={columns}
            height={"400px"}
            margin={"1rem 3rem"}
            selectableRows={false}
          />
        </Grid>
      </Paper>
    </div>
  );
};

export default Alert;
