import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0,
    width: '100%'
  },
  paper: {
    padding: theme.spacing(3, 0),
    margin: '10px 0 0',
    width: 'auto',
    height: '93vh',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: "center",
    boxShadow: 'none',
    background: '#fff'
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    // border: '1px dotted red'
  },
  flexItem: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #e5e5e5',
    borderRadius: 15,
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    width: '375px',
    margin: '1rem 3.2rem',
    textAlign: 'center',
    lineHeight: '75px',
    padding: '12px'
  },
  activeSubMenuList: {
    background: '#eaf4ff',
    borderLeft: '3px solid #0054ba',
    padding: '6px 0 0',
    color: '#505559',
    display: 'flex',
    flexDirection: 'column',
    '& .MuiTypography-colorTextSecondary': {
      color: '#000000',
      padding: '0 1rem',
      textAlign: 'left',
      fontWeight: 500,
      fontSize: 13,
      '& p': {
        padding: '0 1rem !important'
      }
    },
  },
  menuList: {
    background: '#ffffff',
    borderLeft: '0px solid #0054ba',
    padding: '6px 0 0',
    color: '#505559',
    display: 'flex',
    flexDirection: 'column',
    '& .MuiSvgIcon-root': {
      marginTop: 8
    },
    '& .MuiListItemText-root': {
      padding: '6px 0 0px',
      width: '100%',
    },
    activeSubMenuList: {
      background: '#eaf4ff',
      borderLeft: '3px solid #0054ba',
      padding: '6px 0 0',
      color: '#505559',
      display: 'flex',
      flexDirection: 'column',
      '& .MuiTypography-colorTextSecondary': {
        color: '#000000',
        padding: '0 1rem',
        textAlign: 'left',
        fontWeight: 500,
        fontSize: 13,
        '& p': {
          padding: '0 1rem !important'
        }
      },
      '& .makeStyles-activeMenuList-61 .MuiTypography-colorTextSecondary': {

      }
    },
    '& .MuiTypography-colorTextSecondary': {
      color: '#000000',
      padding: '0 1rem',
      textAlign: 'left',
      fontWeight: 500,
      fontSize: 13,
      '& p': {
        padding: '0 1rem !important'
      }
    },
    '& .MuiCollapse-container': {
      background: 'white'
    }
  },
  '& .MuiListItemText-root': {
    padding: ' 0 1rem',
  },
  disableText: {
    pointerEvents: 'none !important',
    '& p': {
      pointerEvents: 'none !important',
    }
  },
  primaryText: {
    color: '#1363c0',
    borderBottom: '2px solid #1363c0',
    textTransform: 'capitalize'
  },
  PopoverClass: {
    width: '265px',
    left: '0px !important'
  }
}));


export { useStyles };