import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import cloneDeep from 'lodash/cloneDeep';
import AdminPageList from './AdminPageList';
import DashboardBasicDetails from './DashboardBasicDetails';
import { getAllPagesForAdmin } from '../../redux/actions';
import Modal from "../../component/modal";
import BarLoader from "react-spinners/BarLoader";
import 'react-toastify/dist/ReactToastify.css';
import { PlusCircleFill } from '../../assets/icons/Icons';
import "./Admin.css";

function Admin(props) {
  const dispatch = useDispatch();

  let viewBuilderJSON = {
    "viewId": null,
    "viewName": null,
    "viewDescription": null,
    "viewStatus": "Draft",
    "htmlTemp": {},
    "projectId": null,
    "categoryId": null,
    "projectName": null,
    "categoryName": null,
    reportPages: true,
    hiddenPages: false,
    globalFilter: false,
    order: false,
    grouped: false,
    subRegion: null,
    msalEnabled: false,
    linkForDocumentation: null,
    displayAddNewProjectModal: false,
    displayAddNewSubprojectModal: false,
    subprojectName: null,
    oktaGroupAdmin: null,
    oktaGroupRead: null,
    logo: null,
  };

  const { state } = props.history.location;


  const selectedSubRegion = useSelector((state) => state.updatedSubRegion);
  const allPagesForAdmin = useSelector((state) => state.getAdminViews);
  const viewBuilder = useSelector((state) => state.viewBuilderObj);
  // const dashBoardStatus = useSelector((state) => state.dashBoardStatus);
  const { activeRegion } = useSelector((state) => state.activeDynamicData);

  const [selectedTab, setSelectedTab] = useState("Active");
  const [selectedActiveModule, setSelectedActiveModule] = useState(null);
  const [showAddNewDashboardModal, setShowAddNewDashboardModal] = useState(false);
  const [activeReports, setActiveReports] = useState([]);
  const [searchItem, setSearchItem] = useState(null);
  const [selectedAdminSubRegion, setSelectedAdminSubRegion] = useState("");

  useEffect(() => {
    if (!state) {
      props.history.push('/');
    }
  }, [props.history, state]);

  useEffect(() => {
    if (activeRegion) {
      const params = {
        "pageType": 'ViewBuilder',
        "region": activeRegion,
        "viewStatus": 'Active'
      }
      dispatch(getAllPagesForAdmin(params));
    }
  }, [dispatch, activeRegion,]);

  console.log('selectedTab', selectedTab)

  useEffect(() => {
    if (Object.keys(viewBuilder).length > 0) {
      const status = viewBuilder.viewBuilderJSON && viewBuilder.viewBuilderJSON.viewStatus;
       if (status === "Draft") {
        setSelectedTab(status);
      }
    }
  }, [viewBuilder]);

  useEffect(() => {
    let { data } = allPagesForAdmin;
    if (Array.isArray(data) && data.length > 0) {
      !!data && Array.isArray(data) && data.sort(value => value.customizableFlag ? -1 : 1)
      let reports = data.filter((el) => el.viewStatus.toLowerCase() === selectedTab.toLowerCase());
      setActiveReports(reports);
     }
  }, [allPagesForAdmin, selectedTab]);

  useEffect(()=>{
    if (selectedSubRegion && Object.keys(selectedSubRegion).length > 0) {
      let { subRegion } = selectedSubRegion;
      setSelectedAdminSubRegion(subRegion);
    }
  },[selectedSubRegion])


  const changeUserSelectedTab = (tab) => {
    setSelectedTab(tab);
    setSearchItem('')
  };

  const selectActiveModule = (val) => {
    setSelectedActiveModule(val);
    setSearchItem('')
  }

  function renderAddNewDashboardModal() {
    return (
      <Modal
        open={showAddNewDashboardModal}
        bodyElement={
          <DashboardBasicDetails
            hideSettingsWindow={(e) => setShowAddNewDashboardModal(false)}
            viewBuilderJSON={viewBuilderJSON}
            region={activeRegion}
            selectedModule={selectActiveModule}
          />
        }
        hideSettingsWindow={(e) => setShowAddNewDashboardModal(false)}
        title={'Add New Dashboard'}
        size={"md"}
      />
    )
  }

  function renderPageListBasedOnSelection() {
    const { history } = props;
    return (
      <AdminPageList
        selectedTabType={selectedTab}
        pages={allPagesForAdmin}
        activeReports={activeReports}
        region={activeRegion}
        history={history}
        selectedActiveModule={selectedActiveModule}
        selectedAdminSubRegion={selectedAdminSubRegion}
      />
    )
  };

  const onChangeSearch = (e, event) => {
    const val = event ? e.target.value : e;
    let searchKey = val.toLowerCase();
    setSearchItem(val);
    console.log('filterData', e, val, searchKey, activeReports);
    let { data } = allPagesForAdmin;
    if (Array.isArray(data) && data.length > 0) {
      !!data && Array.isArray(data) && data.sort(value => value.customizableFlag ? -1 : 1)
      let reports = data.filter((el) => el.viewStatus.toLowerCase() === selectedTab.toLowerCase());
      let dashboards = cloneDeep(reports)
      const filterReports = dashboards.filter(item =>
        item['viewName'].toLowerCase().match(searchKey) ||
        item['buType'].toLowerCase().match(searchKey) ||
        (item['subRegion'] && item['subRegion'].toLowerCase().match(searchKey)) ||
        (item['createdBy'] && item['createdBy'].toLowerCase().match(searchKey)) ||
        (item['updatedBy'] && item['updatedBy'].toLowerCase().match(searchKey))
       );
      console.log('filterData', filterReports);
      setActiveReports(filterReports);
      if (val === '') {
        setActiveReports(reports)
      }
    };
  };


  const suggestedKeywordSearch = (val) => {
    setSearchItem(val);
    onChangeSearch(val, false);
    setSelectedAdminSubRegion(val);
  };

  const renderSubRegionSuggestion = () => {
    if (selectedSubRegion && Object.keys(selectedSubRegion).length > 0) {
      let { subRegionList } = selectedSubRegion;
      if (Array.isArray(subRegionList) && subRegionList.length > 0) {
        return (
          <div className="d-flex tile-new" style={{ border: "0px" }}>
            <span>Suggested Keyword</span>
            {
              subRegionList.map((item, index) =>
                <li className="tile-new" onClick={() => suggestedKeywordSearch(item)} value={item} rowkey={index}>{item}</li>
              )}
          </div>
        )
      }

    }
  };

  return (
    //full-screen-height
    <div className="container-fluid bg-gray pr-5 pl-5 ">
      {renderAddNewDashboardModal()}
      <div className="row my-3 d-flex justify-content-between align-items-center">
        <div>
          <span className="header tile-new" >Dashboards</span>
        </div>
        <div>
          <button className="btn addNewBoard"
            onClick={(e) => {
              setShowAddNewDashboardModal(true);
            }}
          >
            <PlusCircleFill />New Dashboard</button>
        </div>
      </div>
      <div className="bg-white">
        <div className="d-flex pl-3 dashboard-button-row" >
          <button className={`btn btn-plain prettier ${selectedTab === "Active" ? "active" : null}`} onClick={(e) => { changeUserSelectedTab(e.target.innerText) }}>Active</button>
          <button className={`btn btn-plain prettier ${selectedTab === "Inactive" ? "active" : null}`} onClick={(e) => { changeUserSelectedTab(e.target.innerText) }}>Inactive</button>
          <button className={`btn btn-plain prettier ${selectedTab === "Draft" ? "active" : null}`} onClick={(e) => { changeUserSelectedTab(e.target.innerText) }}>Draft</button>
        </div>
        <div className="d-flex flex-column dashboard-button-row  w-100  mt-32" >
          <input
            value={searchItem}
            type={'search'}
            name={'search'}
            className="form-control alert-search prettier"
            onChange={(e) => onChangeSearch(e, true)}
            autoComplete="off"
            placeholder={`Please search by Report Name/ Module Name/ SubRegion/ user's Email`}
          />
          <div className=" d-flex flex-row suggestion">
            <ul class="suggestionsKeyword">
              {renderSubRegionSuggestion()}
            </ul>
          </div>
        </div>
      </div>
      {!!allPagesForAdmin.loading ?
        <div className="loading_wrapper">
          <BarLoader
            size={150}
            color={"#123abc"}
            height={4}
            width={100}
            loading={true}
          />
        </div>
        :
        renderPageListBasedOnSelection()}
    </div>
  );

}

export default Admin;

