import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ChevronRight } from '../../assets/icons/Icons';
import BarLoader from "react-spinners/BarLoader";
import Modal from "../../component/modal";
import {
    getMsalBasedPlaybookContentPages,
    deleteModuleReport,
    deleteSubReport,
    toastTriggered,
    getAllPagesForAdmin,
    updateReportOrder,
    updateSubReportOrder,
    updateSelectedView,
    updatePlaybooksContentPages,
    updateViewBuilderJSON
} from '../../redux/actions';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./Admin.css";
import Collapse from 'react-bootstrap/Collapse';
import utils from '../../utils/index';
import toastProperties from "../../assets/data/toastProperty";
import cloneDeep from 'lodash/cloneDeep';

function AdminPageList(props) {
    const dispatch = useDispatch();

    const { selectedActiveModule, activeReports, selectedAdminSubRegion, selectedTabType } = props;
    const { activeRegion, activeRoutes, activePage, activeRegionSpecificConfigs } = useSelector((state) => state.activeDynamicData);
    const [selectedTab, setSelectedTab] = useState(null);
    const [showSettingsWindow, setShowSettingWindow] = useState(false);
    const [reportOrder, setReportOrder] = useState(false);
    const [subReportOrder, setSubReportOrder] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const [itemList, setItemList] = useState([]);
    const [collapseOpen, setCollapseOpen] = useState(false);
    const [moduleSpecificReports, setModuleSpecificReports] = useState([]);
    const allPagesForAdmin = useSelector((state) => state.getAdminViews);

    const playbookContentPages = useSelector((state) => state.playbookContentPages);
    const UpdateReportOrder = useSelector((state) => state.UpdateReportOrder);

    useEffect(() => {
        const { buType } = activeRegionSpecificConfigs;
        const category = Array.isArray(buType) && buType.filter((el) => el.embedReport);
        if (Array.isArray(category) && category.length > 0) {
            let activeModule = category[0];
            let activeTab = selectedActiveModule ? selectedActiveModule : activeModule.key
            setSelectedTab(activeTab);
        }
    }, [activeRegionSpecificConfigs, activeRegion, selectedActiveModule]);

    useEffect(() => {
        if (activeReports && activeReports.length > 0) {
            let reports = cloneDeep(activeReports);
            console.log('selectedAdminSubRegion', 111,  reports, selectedTab)
            if (selectedTab === 'Playbooks') {
                if(selectedAdminSubRegion){
                    let filteredReports = reports.filter((el) => el.subRegion === selectedAdminSubRegion);
                    console.log('selectedAdminSubRegion', 111, filteredReports, selectedAdminSubRegion)
                    setModuleSpecificReports(filteredReports);
                }
                else{
                    let filteredReports = reports.filter((el) => el.buType === 'Playbooks');
                    console.log('selectedAdminSubRegion',222, filteredReports)
                    setModuleSpecificReports(filteredReports);
                }
            }
            else {
                let { data } = allPagesForAdmin;
                if (Array.isArray(data) && data.length > 0) {
                    !!data && Array.isArray(data) && data.sort(value => value.customizableFlag ? -1 : 1)
                    let reports = data.filter((el) => el.viewStatus === selectedTabType );
                    if(selectedTab){
                        let filteredReports = reports.filter((el) => el.buType.toLowerCase() === selectedTab.toLowerCase());
                        console.log('selectedAdminSubRegion',333, filteredReports)
                        setModuleSpecificReports(filteredReports);
                    }
                 }
            }
        }
    }, [activeReports, allPagesForAdmin, selectedAdminSubRegion, selectedTab, selectedTabType]);

    useEffect(() => {
        let { data } = playbookContentPages;
        data.sort(function (a, b) { return a.order - b.order });
        if (subReportOrder && data && Array.isArray(data) && data.length > 0) {
            setItemList(data);
        }
        else if (subReportOrder && data && Array.isArray(data) && data.length === 0) {
            setItemList([]);
        }
    }, [playbookContentPages, subReportOrder]);

    const RedirecToPreviewViewBuilder = (data) => {
        console.log('RedirecToViewBuilder', data)
        setSelectedData(data);
        const {htmlTemp, customizableFlag, viewId} = data
        let reportIDs = utils.checkValidPBIds(htmlTemp, customizableFlag);
        let selections = {
            reportId: reportIDs['reportId'],
            groupId: reportIDs['groupId'],
            viewID:  viewId,
            pageType: activePage.key,
        }
        let res = {
            data: []
        }
        let payload = { ...res, selections };
        dispatch(updatePlaybooksContentPages(payload));
        dispatch({ type: "GET_PLAYBOOKS_CONTENT_PAGES_REQUEST" });
        dispatch(updateViewBuilderJSON({viewBuilderJSON: {},  success: false,  responseDefault: true, loading: false, type: 'selectedView'}))
        dispatch({ type: "UPDATE_VIEW_BUILDER_JSON" });
        dispatch({ type: "GET_EMBED_TOKEN_REQUEST" });
        localStorage.setItem("viewId", data.viewId);
        props.history.push({
            pathname: activeRoutes.key8,
            state: {
                viewId: data.viewId,
                region: activeRegion,
                pageType: activePage.key
            }
        });
    }

    const changeUserSelectedTab = (tab) => {
        setSelectedTab(tab);
    };

    const trackEvents = (type) => {
        const { viewName, viewDescription, region, subRegion, linkForDocumentation, } = selectedData;
        utils.userTrackingInfo({
            region: region,
            subRegion: subRegion,
            actionType: 'Admin Activity',
            pageName: 'Dashboard',
            reportName: viewName,
            pageDetails: type,
            attributeName: viewDescription,
            reportLink: linkForDocumentation,
        });
    }

    const renderCategories = () => {
        const { buType } = activeRegionSpecificConfigs;
        if (Array.isArray(buType) && buType.length > 0) {
            let uniquebusinessUnit = utils.uniqueArrayData(buType, 'title');
            let data = uniquebusinessUnit.filter(el => el.embedReport && !el.subReport);
            console.log('selectedAdminSubRegion',333, data, uniquebusinessUnit, selectedTab);
            return (
                data.map((item, index) =>
                    <button key={index} className={`btn btn-plain tile-new ${selectedTab === item.key ? "active" : null}`} onClick={(e) => changeUserSelectedTab(item.key)} value={item.key} rowkey={index}>{item.title}</button>
                )
            )
        }
    };

    const parmanentDeleteReport = (id) => {
        let index = activeReports.findIndex(el => el.viewId = id);
        if (index > -1 &&  selectedTabType !== 'Active') {
            let params = { viewId: id };
            window.alert(`Are You Sure You Want to Delete This Report Parmanently? This Action Can't be UNDONE`);
            dispatch(deleteModuleReport(params)).then((res) => {
                trackEvents("Sub Report Parmanent Deleted");
                toastProperties.success["description"] = res.data.message;
                dispatch(toastTriggered([toastProperties.success]));
                const params = {
                    "pageType": 'ViewBuilder',
                    "region": activeRegion,
                    "viewStatus": 'Active'
                }
                dispatch(getAllPagesForAdmin(params));
                setShowSettingWindow(false);
            }).catch((err) => {
                toastProperties.error["description"] = err.message;
                dispatch(toastTriggered([toastProperties.error]));
            });
        }
    };

    const deleteParmanentGroupedSubReport = (id) => {
        let index = activeReports.findIndex(el => el.viewId = id);
        if (index > -1) {
            let params = { subReportId: id };
            window.alert(`Are You Sure You Want to Delete This Report? This Action Can't be UNDONE`);
            dispatch(deleteSubReport(params)).then((res) => {
                trackEvents("Report Parmanent Deleted");
                toastProperties.success["description"] = res.data.message;
                dispatch(toastTriggered([toastProperties.success]));
                const params = {
                    "pageType": 'ViewBuilder',
                    "region": activeRegion,
                    "viewStatus": 'Active'
                }
                dispatch(getAllPagesForAdmin(params));
                setShowSettingWindow(false);
            }).catch((err) => {
                toastProperties.error["description"] = err.message;
                dispatch(toastTriggered([toastProperties.error]));
            });
        }
    };

    const deleteReport = () => {
        const { viewName, viewDescription, buType, globalFilter, region, subRegion, order, reportPages, hiddenPages, linkForDocumentation, htmlTemp, viewId, grouped, msalEnabled, customizableFlag, tagsKeyword } = selectedData;
        if (selectedData.viewId !== "" &&  selectedTabType === 'Active') {
            let obj = {
                viewId: viewId,
                buType: buType,
                viewName: viewName,
                viewDescription: viewDescription,
                viewStatus: 'Inactive',
                region: region,
                htmlTemp: htmlTemp,
                linkForDocumentation: linkForDocumentation,
                subRegion: subRegion,
                order: order,
                grouped: grouped,
                reportPages: reportPages,
                hiddenPages: hiddenPages,
                globalFilter: globalFilter,
                msalEnabled: msalEnabled,
                customizableFlag: customizableFlag,
                tagsKeyword: tagsKeyword
            }
            dispatch(updateSelectedView(obj)).then((res) => {
                trackEvents("Report Marked Inactive");
                toastProperties.success["description"] = 'Report Marked Inactive Successfully';
                dispatch(toastTriggered([toastProperties.success]));
                const params = {
                    "pageType": 'ViewBuilder',
                    "region": activeRegion,
                    "viewStatus": 'Active'
                }
                dispatch(getAllPagesForAdmin(params));
                setShowSettingWindow(false);
            }).catch((err) => {
                toastProperties.error["description"] = err.message;
                dispatch(toastTriggered([toastProperties.error]));
            })
        }
    };

    const viewDetails = () => {
        let { data } = playbookContentPages;
        data.sort(function (a, b) { return a.order - b.order });
        const { viewName, viewDescription, buType, viewStatus, region, subRegion, createdBy, updatedBy, linkForDocumentation, htmlTemp, viewId, grouped, msalEnabled, tagsKeyword } = selectedData;
        return (
            <div className="col-sm-12 col-md-12">
                <div className="item-container tile-new"> <label> Report Name : </label>  {viewName}</div>
                <div className="item-container tile-new"> <label> Report Description : </label>  {viewDescription}</div>
                <div className="item-container tile-new"> <label> RLS Based Report : </label>  {msalEnabled ? 'Yes' : 'No'}</div>
                <div className="item-container tile-new"> <label> Module Type : </label>  {buType}</div>
                <div className="item-container tile-new"> <label> Report Status : </label>  {viewStatus}</div>
                <div className="item-container tile-new"> <label> Region  : </label>  {region}</div>
                {
                    subRegion && subRegion !== 'NULL' && subRegion !== 'null' ?
                        <div className="item-container tile-new">  <label> Sub Region : </label> {subRegion} </div> : null
                }
                <div className="item-container tile-new"> <label> Created By :  </label> {createdBy}</div>
                <div className="item-container tile-new"> <label> Updated By :  </label> {updatedBy}</div>
                <div className="item-container tile-new"> <label>  Link  : </label> {linkForDocumentation ? linkForDocumentation : htmlTemp && Object.keys(htmlTemp).length > 0 && htmlTemp.reportLink}</div>
                <div>
                    <div className="item-container tile-new" aria-expanded="false" aria-controls="collapseExample" onClick={(e) => setCollapseOpen(!collapseOpen)}> <label> Tags & Keyword : </label>   </div>
                    {
                        Array.isArray(tagsKeyword) && tagsKeyword.length > 0 && tagsKeyword.map((name, index) => {
                            return (
                                <Collapse   key={index}  in={true}>
                                    <ul><li>{name}</li></ul>
                                </Collapse>
                            )
                        })
                    }
                </div>
                {
                    !grouped ?
                        <div className="item-container tile-new"> <label> Grouped Report : </label>   No </div>
                        :
                        <div>
                            <div className="item-container tile-new" aria-expanded="false" aria-controls="collapseExample" onClick={(e) => setCollapseOpen(!collapseOpen)}> <label>  Grouped Report  : </label>     Yes  <span className="caretdown"></span>  </div>
                            <Collapse in={collapseOpen}>
                                <div className="tile-new" id="collapseExample">
                                    <label>  Sub Report List : </label>
                                    {Array.isArray(data) && data.length === 0 ? 'No Report Available' : null}
                                </div>
                            </Collapse>
                        </div>
                }
                {
                    grouped && Array.isArray(data) && data.length > 0 &&
                    data.map((subReport, index) => {
                        return (
                            <Collapse   key={index}  in={collapseOpen}>
                                <div key={index} className="item-container-column tile-new" id="collapseExample">
                                    <div className="subReport-container pt-2 pb-2"> <label>  Name : </label> {subReport.displayName}</div>
                                    <div className="subReport-container pt-2 pb-2"> <label>  Description : </label> {subReport.viewDescription}</div>
                                    <div className="subReport-container pt-2 pb-2"> <label>  Link : </label> {subReport.linkForDocumentation ? subReport.linkForDocumentation : subReport.htmlTemp && Object.keys(subReport.htmlTemp).length > 0 && subReport.htmlTemp.reportLink}</div>
                                    <div className="arrangments">
                                        <button className="btn btn-danger tile-new" onClick={(e) => deleteParmanentGroupedSubReport(subReport.subReportId)}>  Delete Sub Report </button>
                                    </div>
                                </div>
                            </Collapse>
                        )
                    })
                }
                <div className="arrangments">
                    <button className="btn btn-danger tile-new" onClick={(e) =>  selectedTabType !== 'Active' ? parmanentDeleteReport(viewId) : deleteReport()}> {  selectedTabType !== 'Active' ? ' Paramanent Delete Report' : 'Delete Report'} </button>
                </div>
            </div>
        )
    };

    const saveReportOrder = () => {
        let updatedReportOrder = [];
        let newOrder = cloneDeep(itemList)
        newOrder.map((report, index) => {
            report.order = index;
            let reportOrder = {};
            reportOrder.region = report.region
            if (subReportOrder) {
                reportOrder.subReportId = report.subReportId;
            }
            else {
                reportOrder.viewId = report.viewId;
            }
            reportOrder.order = report.order;
            updatedReportOrder.push(reportOrder)
            return report;
        });
        console.log('newReportOrder', newOrder, updatedReportOrder);
        let params = { reportsDetails: updatedReportOrder };

        if (subReportOrder) {
            dispatch(updateSubReportOrder(params)).then((res) => {
                toastProperties.success["description"] = res.data.message;
                dispatch(toastTriggered([toastProperties.success]));
                setShowSettingWindow(false);
                trackEvents("Report Order Adjusted");
            })
                .catch((err) => {
                    toastProperties.error["description"] = err.message;
                    dispatch(toastTriggered([toastProperties.error]));
                })
        }
        else {
            dispatch(updateReportOrder(params)).then((res) => {
                toastProperties.success["description"] = res.data.message;
                dispatch(toastTriggered([toastProperties.success]));
                setShowSettingWindow(false);
                trackEvents("Sub Report Order Adjusted");
            })
                .catch((err) => {
                    toastProperties.error["description"] = err.message;
                    dispatch(toastTriggered([toastProperties.error]));
                })
        }
    };

    const handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        var updatedList = [...itemList];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
        setItemList(updatedList);

    };
    console.log('updatedReportList', selectedTab)

    const renderSelectedReports = () => {
        if (itemList.length > 0) {
            return (
                <div className="reportOrder">
                    <DragDropContext onDragEnd={handleDrop}>
                        <Droppable droppableId="list-container">
                            {(provided) => (
                                <div
                                    className="list-container"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {itemList.map((item, index) => (
                                        <Draggable key={subReportOrder ? item.displayName : item.viewName} draggableId={subReportOrder ? item.displayName : item.viewName} index={index}>
                                            {(provided) => (
                                                <div
                                                    className="item-container tile-new"
                                                    ref={provided.innerRef}
                                                    {...provided.dragHandleProps}
                                                    {...provided.draggableProps}
                                                >

                                                    {subReportOrder ? item.displayName : item.viewName}
                                                    <span className="reOrder"><i class="fa fa-bars" aria-hidden="true"></i></span>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <div className="arrangments">
                        {
                            UpdateReportOrder.loading ?
                                <div className="waiting_Loader">
                                    <div className="prettier">Please wait...</div>
                                    <BarLoader
                                        size={150}
                                        color={"#123abc"}
                                        height={4}
                                        width={100}
                                        loading={true}
                                    />
                                </div>
                                :
                                <button className="btn btn-danger tile-new" onClick={() => saveReportOrder()}>  Save </button>
                        }
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className="arrangments">
                    No Report Available
                </div>
            )
        }
    };

    const renderModalTitle = () => {
        if (reportOrder) {
            return (
                <div className="alignment">
                    <div className="list-container"> {selectedTab} - Report Order</div>
                    <div className="react-tabs tile-new align-items-start">Region : {activeRegion}</div>
                    {
                        selectedData.subRegion && selectedData.subRegion !== 'null' ?
                            <div className="react-tabs tile-new align-items-end">SubRegion : {selectedData.subRegion}</div>
                            : null
                    }
                </div>
            )
        } else if (subReportOrder) {
            return (
                <div className="alignment">
                    <div className="list-container tile-new"> {selectedTab} - Sub Report Order</div>
                    <div className="react-tabs tile-new align-items-start">Region : {activeRegion}</div>
                    {
                        selectedData.subRegion && selectedData.subRegion !== 'null' ?
                            <div className="react-tabs tile-new align-items-end">SubRegion : {selectedData.subRegion}</div>
                            : null
                    }
                </div>
            )
        } else {
            return (
                `Report Details`
            )
        }
    }

    const renderReportDetails = () => {
        return (
            <Modal
                open={showSettingsWindow}
                bodyElement={
                    reportOrder ? renderSelectedReports() : viewDetails()
                }
                hideSettingsWindow={() => { setShowSettingWindow(false); setCollapseOpen(false) }}
                title={renderModalTitle()}
                size={'md'}
            />
        )
    };

    const reportOperations = (data) => {
        setSelectedData(data);
        setShowSettingWindow(true);
        setReportOrder(false);
        setSubReportOrder(false);
        trackEvents("Report Viewed");
        if (data.grouped) {
            let { htmlTemp, customizableFlag } = data;
            let reportId = utils.checkValidPBIds(htmlTemp, customizableFlag);
            console.log('APICALL-groupedSubReports', reportId, data)
            const params = {
                ...reportId,
                viewID: data['viewId'],
                pageType: data.buType
            };
            dispatch(getMsalBasedPlaybookContentPages(params));
        }
    };

    const sortingReportOrder = (data) => {
        let subRegion = data.subRegion;
        let activeModuleReports = activeReports.filter((el) => el.buType === selectedTab);
        let reports = subRegion && subRegion !== 'NULL' && subRegion !== 'null' ? activeModuleReports.filter((el) => el.subRegion === subRegion) : activeModuleReports;
        console.log('sortingReportOrder', subRegion, activeReports, activeModuleReports, reports)
        reports.sort(function (a, b) { return a.order - b.order });
        setReportOrder(true);
        setSelectedData(data);
        setItemList(reports);
        setShowSettingWindow(true);
        setSubReportOrder(false);
    };

    const sortingSubReportOrder = (data) => {
        setSelectedData(data);
        if (data.grouped) {
            let { htmlTemp, customizableFlag } = data;
            let reportId = utils.checkValidPBIds(htmlTemp, customizableFlag);
            const params = {
                ...reportId,
                viewID: data['viewId'],
                pageType: data.buType
            };
            dispatch(getMsalBasedPlaybookContentPages(params)).then((res) => {
                setReportOrder(true);
                setSubReportOrder(true);
                setShowSettingWindow(true);
                console.log('getMsalBasedPlaybookContentPages', res);
            }).catch((err) => {
                console.log('getMsalBasedPlaybookContentPages', err);
            })
        }
    };

    const renderTiles = () => {
        const { loading, error, userMessage } = props.pages;

        if (!!loading) {
            return (
                <div className="loading_wrapper">
                    <BarLoader
                        size={150}
                        color={"#123abc"}
                        height={4}
                        width={100}
                        loading={true}
                    />
                </div>
            )
        }
        else if (!loading && activeReports && activeReports.length > 0) {
            moduleSpecificReports.sort(function (a, b) { return b.order - a.order });
            return moduleSpecificReports.map((data, index) => {
                return (
                    <div key={index} className="col-sm-12 col-md-4 tile-wrapper" >
                        <div className="col-sm-12 col-md-12 row tile">
                            <div className="col-sm-9 col-md-9 pr-0 alignment">
                                <div className="tile-name tile-new">{data.viewName}</div>
                                {
                                    data.subRegion && data.subRegion !== 'NULL' && data.subRegion !== 'null' ?
                                        <div className="desc tile-new">{data.subRegion}</div> : null
                                }
                            </div>
                            <div className="col-sm-3 col-md-3 pr-0">
                                <div className="col-sm-12 col-md-12  pr-0 pl-0 arrangments">
                                    <div className="col-md-6 pr-0 pl-0 align-items-center icon-height" onClick={() => reportOperations(data)}>
                                        <span className="d-flex" data-toggle="tooltip" data-placement="top" title="Report Details"><i class="fa fa-eye" aria-hidden="true" style={{ fontSize: '15px' }}></i></span>
                                    </div>
                                    <div className="col-md-6  pr-0 pl-0 align-items-center icon-height" onClick={() => sortingReportOrder(data)}>
                                        <span className="d-flex" data-toggle="tooltip" data-placement="top" title="Set Report Order"> <i class="fa fa-bars" aria-hidden="true" style={{ fontSize: '15px' }}></i> </span>
                                    </div>
                                    {
                                        data.grouped ?
                                            <div className="col-md-6  pr-0 pl-0 align-items-center icon-height" onClick={() => sortingSubReportOrder(data)}>
                                                <span className="d-flex" data-toggle="tooltip" data-placement="top" title="Set Sub Report Order"> <i class="fas fa-sort-amount-up" aria-hidden="true" style={{ fontSize: '15px' }}></i> </span>
                                            </div> : null
                                    }
                                </div>
                                <div className="col-md-12 align-items-center d-flex tile-new pl-0 pr-0" onClick={() => RedirecToPreviewViewBuilder(data)}>
                                    <span>Manage</span> <ChevronRight />
                                </div>
                            </div>
                        </div>

                    </div>
                )
            });
        }
        else if (!loading && activeReports && activeReports.length === 0) {
            return (
                <div className="col-sm-12">
                    <span> {'There is no data available'} </span>
                </div>
            )
        }
        else if (!!error) {
            return (
                <div className="col-sm-12">
                    <span> {userMessage} </span>
                </div>
            )
        }
    }

    return (
        <div className="bg-white">
            <div className="d-flex dashboard-button-row" >
                {renderCategories()}
            </div>
            <div className="row bg-white tile-list" >
                {renderTiles()}
                {renderReportDetails()}
            </div>
        </div>
    );
}

export default AdminPageList;